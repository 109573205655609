@use '../config/' as *;
.waf-multisport-legend {
    padding-block: var(--space-20) 0;
    margin-bottom: var(--space-20);
    @extend %mx-2-neg;
    @extend %relative;
    &::before {
        content: '';
        height: calc(100% - 13.5rem);
        border-radius: 0 0 1.6rem 1.6rem;
        background: linear-gradient(90deg, hsl(var(--hsl-neutral-100)/1) 22%, hsl(var(--hsl-primary-90)/1) 71%, hsl(var(--hsl-primary-55)/1) 88%);
        @extend %zindex-multisportlegend-bg;
        @extend %w-100;
        @extend %position-t-l;
    }
    .waf-head {
        .title {
            @extend %title-40;
            @extend %neutral-0;
            @extend %mb-10;
            @include title-with-icon(shape, trophy, 20, neutral-0);
        }
    }
    .card {
        @extend %gap-10;
        @extend %flex-column;
        &-desc {
            @extend %pb-6;
            @extend %body-lb;
            @extend %neutral-5;
        }
        &-info {
            .desc {
                @extend %pb-6;
                @extend %body-l;
                @extend %neutral-20;
                &.strong {
                    font-weight: 700;
                }
            }
        }
        &-list {
            @extend %mr-4-neg;
            @include card-count(1.2, var(--space-4));
        }
        &-item {
            height: 27rem;
            @extend %flex-column;
            @extend %p-6;
            @extend %border-radius-l;
            @extend %bg-neutral-0;
            @include border(1, neutral-20);
            &:last-child {
                @extend %mr-4;
            }
        }
        &-number {
            @extend %title-20;
            @extend %neutral-100;
        }
        &-title {
            @extend %mb-2;
            @extend %body-lb;
            @extend %neutral-100;
        }
        &-btn {
            @extend %mt-auto;
            .btn-outline {
                width: max-content;
                @extend %btn-outline;
            }
        }
    }
    .btn-close {
        @include position(2.8rem, 2.8rem);
        &:hover {
            &::after {
                @extend %primary-55;
            }
        }
        &:focus {
            &::after {
                @extend %primary-85;
            }
        }
        &::after {
            @extend %primary-50;
            @include icon(close, 24);
        }
        .btn-text {
            @extend %font-0;
        }
    }
    .modal {
        @extend %d-none;
        &.active {
            display: flex;
        }
        &-wrap {
            height: max-content;
            max-height: calc(var(--window-inner-height) - 8rem);
            @include custom-scroll();
            @extend %w-100;
            @extend %pt-22;
            @extend %px-4;
            @extend %bg-neutral-0;
            @extend %border-radius-l;
            @extend %relative;
            @extend %flex-column;
        }
        &-window {
            align-items: center;
            @extend %p-10-8;
            @include bg(neutral-100, 7);
        }
        &-body {
            overflow-y: auto;
            max-width: 100%;
            margin: unset;
            @extend %h-100;
        }
        .title {
            @extend %title-40;
        }
        .sub-title {
            @extend %title-50;
        }
    }
    .legend {
        &s {
            &-item {
                @extend %py-4;
                @include border(1, neutral-20, 10, bottom);
            }
            &-head {
                @extend %pb-10;
                @extend %bg-neutral-0;
                @include position(-0.2rem, null, null, 0, sticky);
            }
        }
        &-name {
            @extend %title-60;
        }
        &-meta {
            @extend %flex-n-c;
            @extend %gap-2;
        }
    }
    .meta {
        &-item {
            @extend %flex-n-c;
            @extend %neutral-70;
            &:not(:last-child)::after {
                content: "";
                width: .4rem;
                aspect-ratio: 1;
                @extend %circle-radius;
                @extend %ml-2;
                @extend %bg-neutral-20;
            }
        }
        &-origin {
            @extend %flex-n-c;
            @extend %gap-2;
            img {
                width: 1.6rem;
                min-width: 1.6rem;
                aspect-ratio: 1;
                object-fit: cover;
                @extend %circle-radius;
            }
        }
        &-category {
            font-weight: 700;
            flex-shrink: 0;
        }
    }
}
@include mq(col-tablet) {
    .waf-multisport-legend {
        .waf-head {
            width: 50%;
            .title {
                font-size: 4rem;
            }
        }
        .card {
            gap: var(--space-24);
            &-desc {
                font-size: 2rem;
            }
            &-content {
                @include flex-config(flex);
            }
            &-list {
                margin-right: var(--space-0);
                // @include card-count(3, var(--space-6));
            }
            &-item {
                &:last-child {
                    margin-right: var(--space-0);
                }
            }
            &-desc {
                width: 35%;
                padding-bottom: var(--space-0);
            }
            &-info {
                padding-left: var(--space-12);
                width: 65%;
            }
        }
        .modal {
            &-wrap {
                max-height: 71%;
                padding-inline: var(--space-10);
                padding-bottom: var(--space-10);
            }
        }
    }
}
@include mq(col-lg) {
    .waf-multisport-legend {
        .waf-head {
            width: 35%;
        }
        .legend {
            &s {
                &-list {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0 var(--space-6);
                }
                &-item {
                    width: calc(50% - 1.2rem);
                    @include flex-config(flex, row, space-between, center);
                    &::nth-child(2) {
                        border-top: 0;
                    }
                }
            }
            &-name {
                margin-bottom: 0;
                max-width: 57%;
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-multisport-legend {
        .card-list {
            @include card-count(4, var(--space-6));
        }
    }
}