@forward "./main.scss";
@forward './module/common-hero';
@forward '../scss/module/sports-hero';
@forward '../scss/module/sports-details';
@forward './module/info-cards';
@forward './events/eventstrip';
@forward './events/featured-events';
@forward './listing/listing-common';
@forward "./module/banner";
@forward "./module/multisport-legend";
@forward "./module/commitment";
@forward './module/event-participate';
@forward "./module/static";
@forward "./module/static/faq";
@forward "./module/sports-shop";
@forward "./module/sports-discover";
@forward "./module/triathlon-live";
@forward "./standings/medal-tally";
@forward './stories/story';
@forward "./swiper/swiper-bundle";
//ovverriding code for eventstrip widget
@use './config/' as *;
.waf-row-event {
    border-radius: var(--border-radius-m);
    @extend %hidden;
    &::before {
        height: 20%;
    }
    &::after {
        height: 100%;
        top: 20%;
        bottom: unset;
        background: linear-gradient(180deg, hsla(var(--hsl-neutral-100)/0) 0%, hsla(var(--hsl-neutral-100)/0.99) 30.25%, hsla(var(--hsl-neutral-100)/1) 100%);
    }
}
.waf-broadcast {
    &.no-card-list {
      .streaming {
        margin-bottom: 0;
        padding-bottom: var(--space-4);
      }
    }
  }
.agegroup {
    .waf-row-event {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
    }
    .waf-component {
        &.waf-event-strip {
            margin-bottom: 3.8rem;
        }
    }
}
.waf-component {
    &.waf-event-strip {
        margin-bottom: 25rem;
    }
    &.waf-featured-event.waf-events {
        // @extend %pb-20;
        padding-bottom: 8rem;
    }
    &.waf-listing {
        @extend %pt-0;
        @extend %pb-20;
        @extend %mb-0;
    }
}
.waf-event-strip {
    @extend %zindex-content;
    .head-tab {
        a {
            border-color: var(--neutral-0);
            @extend %bg-transparent;
        }
    }
}
.waf-events {
    .title {
        color: var(--neutral-100);
        &:before {
            content: unset;
        }
    }
    .card-item {
        cursor: pointer;
        @extend %relative;
        &::after {
            content: unset;
            background-color: hsla(var(--hsl-pure-black-0)/20%);
            @extend %position-t-l;
            @extend %w-100;
            @extend %h-100;
        }
        &:hover {
            &::after {
                content: "";
            }
        }
    }
}
.waf-faq {
    background: linear-gradient(135deg, var(--neutral-0), var(--primary-10));
    &.waf-common-static {
        .card-section {
            padding-bottom: 16.6rem;
        }
    }
    .waf-body {
        @extend %my-0;
    }
    .card-label {
        max-width: 28.3rem;
    }
    .card-section {
        @extend %bg-transparent;
    }
    .btn-icon-ar {
        position: absolute;
        bottom: var(--space-20);
    }
}
.waf-sport-class {
    padding-block: var(--space-20);
    margin-bottom: var(--space-10);
    @extend %mx-2-neg;
    @extend %relative;
    @extend %accordion-common;
    &::before {
        content: '';
        border-radius: 0 0 1.6rem 1.6rem;
        background: linear-gradient(90deg, hsl(var(--hsl-neutral-100)/1) 22%, hsl(var(--hsl-primary-90)/1) 71%, hsl(var(--hsl-primary-55)/1) 88%);
        @extend %zindex-sportsclass-bg;
        @extend %h-100;
        @extend %w-100;
        @extend %position-t-l;
    }
    .waf-head {
        .title {
            @extend %title-40;
            @extend %neutral-0;
            @extend %mb-10;
            @extend %capitalize;
            @include title-with-icon(shape, trophy, 20, neutral-0);
        }
    }
    .sports-info {
        @extend %mb-8;
        .title,
        .desc {
            @extend %pb-6;
            @extend %mb-0;
            @extend %neutral-5;
        }
        .title {
            @extend %title-40;
        }
        .desc {
            @extend %body-l;
            a.desc {
                @extend %body-lb;
            }
        }
    }
    .accordion {
        &-item {
            &:not(:last-child) {
                @extend %mb-4;
            }
        }
        &-header {
            @extend %neutral-0;
            @include border(1, neutral-0, 10, bottom);
        }
    }
    .text {
        @extend %pb-4;
        @extend %body-l;
        @extend %neutral-5;
    }
    .card {
        @extend %gap-4;
        @extend %flex-column;
        &-item {
            @extend %p-4;
            @extend %border-radius-m;
            @extend %bg-neutral-5;
            @extend %shadow-medium;
        }
        &-title,
        &-desc {
            @extend %neutral-100;
        }
        &-title {
            @extend %pb-4;
            @extend %uppercase;
            @extend %title-50;
        }
        &-desc {
            @extend %pb-2;
            @extend %body-m;
        }
    }
}
.waf-listing {
    &.waf-overlay-list {
        .article-list {
            grid-auto-flow: column;
            grid-auto-columns: calc(100% - 4.8rem);
            grid-template-columns: unset;
        }
    }
}
.waf-row-event {
    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
        @extend %d-none;
    }
}
.load-bg-pattern {
    .waf-multisport-legend {
        &::before {
            @include bgImg("cssimages/pattern/motorsport-legend.png", center, cover);
        }
    }
    .waf-sport-class {
        &::before {
            @include bgImg("cssimages/pattern/sport-class-mob.png", center, cover);
        }
    }
    .waf-faq {
        @include bgImg('cssimages/pattern/blue-gradient-left.png', top left, 200%);
    }
    .waf-sports-shop {
        &::before {
            @include bgImg("cssimages/sports-store-mob.png", null, cover);
        }
    }
}
@include mq(col-tablet) {
    .waf-faq {
        background-position: top left;
        background-size: cover;
        .waf-body {
            margin-block: 0;
        }
        .btn-icon-ar {
            position: unset;
        }
        .card-label {
            font-size: 3.2rem;
            max-width: unset;
        }
    }
    .waf-event-strip {
        .head-tab {
            a {
                background: transparent;
                color: var(--neutral-0);
                &:hover {
                    background-color: hsl(var(--hsl-neutral-0)/0.3);
                }
                &:focus {
                    border-color: var(--primary-85);
                }
            }
        }
    }
    .waf-sport-class {
        .waf-head {
            .title {
                font-size: 4rem;
            }
        }
        .card {
            flex-direction: row;
            &-item {
                flex: 1;
            }
        }
    }
    .waf-listing {
        .head-wrap {
            margin-bottom: var(--space-10);
        }
        &.waf-overlay-list {
            .article-list {
                grid-auto-columns: calc(100% - 45.8rem);
            }
        }
    }
    .waf-row-event {
        .swiper-button-prev,
        .swiper-button-next,
        .swiper-pagination {
            display: none;
        }
    }
    .load-bg-pattern {
        .waf-sport-class {
            &::before {
                @include bgImg("cssimages/pattern/sport-class-web.png", center, cover);
            }
        }
        .waf-sports-shop {
            &::before {
                @include bgImg("cssimages/sports-store-web.png", null, cover);
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-listing {
        &.waf-overlay-list {
            .article-list {
                grid-auto-flow: unset;
                grid-auto-columns: unset;
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}