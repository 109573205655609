@use '../config/' as *;
.waf-component {
    &.waf-sports-shop {
        padding-top: calc(var(--space-24) + var(--space-3));
        @extend %pb-0;
        @extend %mb-20;
    }
}
.waf-sports-shop {
    @extend %px-3;
    @extend %relative;
    @include article-listing(card);
    &::before {
        content: "";
        height: calc(70% - 11rem);
        background: linear-gradient(45deg, var(--neutral-100), hsla(var(--hsl-neutral-100)/.7));
        @extend %position-t-l;
        @extend %border-radius-l;
        @extend %w-100;
        @extend %zindex-content-bg;
    }
    .layout-wrapper {
        max-width: unset;
    }
    .shop-logo {
        max-width: 25.5rem;
        @extend %mb-8;
    }
    .title {
        @extend %neutral-0;
        @extend %title-20;
    }
    .desc {
        @extend %neutral-5;
        @extend %title-50;
        @extend %mb-0;
    }
    .img-box {
        aspect-ratio: 4/3;
    }
    .article {
        &-list {
            grid-auto-flow: column;
            grid-auto-columns: calc(100% - 4.4rem);
            grid-template-columns: unset;
            overflow-x: auto;
            @extend %mr-5-neg;
            @extend %mt-20;
        }
        &-item {
            box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.1);
            @extend %bg-neutral-0;
            &:hover {
                .article-thumbnail img {
                    transform: unset;
                }
            }
        }
        &-title {
            font-size: 2rem;
            height: auto;
            @extend %d-block;
            @extend %mb-2;
        }
        &-description {
            @include truncate-text(2);
            @extend %neutral-70;
            @extend %mb-6;
        }
        &-thumbnail {
            img {
                object-fit: contain;
            }
        }
    }
    .tagline {
        a {
            width: max-content;
            @extend %gap-2;
            @extend %btn-outline-text-icon;
            &::after {
                transform: rotate(-45deg);
                @include icon(arrow-small-right, 16);
                @extend %mt-1;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-sports-shop {
        margin-inline: var(--space-7);
        padding-top: var(--space-18);
        padding-inline: var(--space-10);
        &::before {
            height: calc(100% - 19rem);
        }
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .article {
            &-list {
                grid-auto-columns: calc((100% / 3) - 1.2rem);
                margin-right: 0;
            }
        }
        .desc {
            font-size: 2.4rem;
        }
    }
}
@include mq(col-lg) {
    .waf-sports-shop {
        .article {
            &-list {
                grid-auto-columns: calc((100% / 4) - 1.2rem);
            }
            &-item {
                &:hover {
                    .article-thumbnail img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
}