@use '../config/' as *;
.waf-sports-discover {
    @extend %common-sports-table;
    .layout-wrapper {
        max-width: unset;
    }
    &.waf-component {
        @extend %py-0;
        @extend %mb-20;
    }
    .discover {
        &-wrap {
            @extend %relative;
            @extend %pt-10;
            &::before {
                content: "";
                background: linear-gradient(268.76deg, var(--primary-90) -0.19%, var(--primary-50) 99.83%);
                @extend %w-100;
                @extend %h-100;
                @extend %position-t-l;
                @extend %border-radius-m;
            }
        }
        &-head {
            @extend %relative;
            @extend %neutral-0;
            @extend %mb-10;
            @extend %px-4;
        }
        &-body {
            box-shadow: 0 1rem 4.2rem 0 rgba(0, 0, 0, 0.1);
            @include border(1, neutral-5);
            @extend %relative;
            @extend %border-radius-m;
            @extend %bg-neutral-0;
            @extend %p-4;
        }
    }
    .title {
        @extend %mb-6;
    }
    .text {
        @extend %body-l;
    }
    .table {
        max-height: 33.3rem;
        overflow-y: auto;
        @include custom-scroll();
        &-title {
            @extend %d-none;
        }
    }
}
@include mq(col-tablet) {
    .waf-sports-discover {
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .title {
            font-size: 4rem;
            line-height: 4.8rem;
            margin-bottom: var(--space-4);
        }
        .table-title {
            display: block;
            font-size: 2rem;
            margin-bottom: var(--space-4);
        }
    }
}
@include mq(col-desktop) {
    .waf-sports-discover {
        .discover {
            &-wrap {
                padding-top: 0;
                padding-inline: var(--space-20);
                gap: 9.5%;
                margin-bottom: 6.2rem;
                @include flex-config(flex, null, null, flex-start);
                &::before {
                    max-height: 48.4rem;
                }
            }
            &-head {
                width: 34%;
                margin-bottom: 0;
                padding-inline: 0;
                padding-top: var(--space-20);
            }
            &-body {
                width: 66%;
                padding: var(--space-10) var(--space-12);
                margin-top: var(--space-14);
            }
        }
    }
}