@use "../config" as *;

.waf-commitment {
    padding-block: var(--space-10);
    margin-block: var(--space-0);
    &::before {
        content: '';
        @extend %zindex-commitment-bg;
        @extend %w-100;
        @extend %h-100;
        @extend %position-t-l;
        @include bgImg("cssimages/pattern/blue-gradient-left.png", top, cover);
    }
    .waf-head {
        .title {
            @extend %title-40;
        }
    }
    .title {
        @extend %mb-6;
        @extend %neutral-100;
    }
    .card {
        &-list {
            @extend %gap-6;
            @extend %flex-column;
        }
        &-item {
            background-color: hsla(var(--hsl-neutral-0)/0.3);
            @extend %p-6;
            @extend %border-radius-m;
            @include border(1,neutral-20);
            .title {
                @include truncate(3,24);
            }
        }
    }
    .desc {
        @extend %body-l;
        @extend %neutral-70;
    }
}
@include mq(col-tablet) {
    .waf-commitment {
        &::before {
            background-size: 100% 100%;
        }
        .title {
            font-size: 4rem;
        }
        .card {
            &-list {
                flex-direction: row;
                flex-wrap: wrap;
            }
            &-item {
                width: calc(50% - var(--space-6));
            }
        }
    }
}
@include mq(col-lg) {
    .waf-commitment {
        .card-item {
            flex: 1;
        }
    }
}